<template>
  <ValidationObserver v-slot="{ invalid }">
    <form autocomplete="off">
      <vs-row>
        <ValidationProvider
          name="CEP"
          :rules="{ required: required }"
          v-slot="{ errors }"
          class="md:w-1/2 w-full"
          tag="vs-col"
        >
          <vs-input
            label="CEP"
            class="w-full"
            v-model="(endereco || {}).ZipCode"
            @change="buscarCep"
            size="large"
            :disabled="disabled"
            :danger="!!errors[0]"
            :danger-text="errors[0]"
            v-mask="['#####-###']"
          />
        </ValidationProvider>
      </vs-row>

      <vs-row>
        <ValidationProvider
          name="endereço"
          :rules="{ required: required }"
          v-slot="{ errors }"
          class="md:w-4/5 w-full"
          tag="vs-col"
        >
          <vs-input
            label="Endereço"
            class="w-full"
            v-model="(endereco || {}).AddressName"
            size="large"
            @change="
              $emit('updateValue', {
                ...dadosEndereco,
                AddressName: (endereco || {}).AddressName
              })
            "
            :disabled="disabled"
            :danger="!!errors[0]"
            :danger-text="errors[0]"
          />
        </ValidationProvider>

        <ValidationProvider
          name="número"
          :rules="{ required: required }"
          v-slot="{ errors }"
          class="md:w-1/5 w-full"
          tag="vs-col"
        >
          <vs-input
            class="w-full"
            label="Nº"
            v-model="(endereco || {}).Number"
            size="large"
            @change="
              $emit('updateValue', {
                ...dadosEndereco,
                Number: (endereco || {}).Number
              })
            "
            :disabled="disabled"
            :danger="!!errors[0]"
            :danger-text="errors[0]"
          />
        </ValidationProvider>
      </vs-row>

      <vs-row>
        <ValidationProvider
          name="Complemento"
          v-slot="{ errors }"
          class="md:w-1/2 w-full pt-4"
          tag="vs-col"
        >
          <vs-input
            class="w-full"
            label="Complemento"
            v-model="(endereco || {}).Complement"
            @change="
              $emit('updateValue', {
                ...dadosEndereco,
                Complement: (endereco || {}).Complement
              })
            "
            size="large"
            :disabled="disabled"
            :danger="!!errors[0]"
            :danger-text="errors[0]"
          />
        </ValidationProvider>

        <ValidationProvider
          name="bairro"
          :rules="{ required: required }"
          v-slot="{ errors }"
          class="md:w-1/2 w-full pt-4"
          tag="vs-col"
        >
          <vs-input
            label="Bairro"
            class="w-full"
            v-model="(endereco || {}).Neighborhood"
            @change="
              $emit('updateValue', {
                ...dadosEndereco,
                Neighborhood: (endereco || {}).Neighborhood
              })
            "
            size="large"
            :disabled="disabled"
            :danger="!!errors[0]"
            :danger-text="errors[0]"
          />
        </ValidationProvider>
      </vs-row>

      <vs-row>
        <ValidationProvider
          name="UF"
          :rules="{ required: required }"
          v-slot="{ errors }"
          class="md:w-1/2 w-full pt-4"
          tag="vs-col"
        >
          <vs-select
            autocomplete
            @change.passive="loadCities()"
            class="large w-full border-select"
            label="UF"
            :value="
              stateProvinces && stateProvinces.length && (endereco || {}).StateProvinceId
                ? (endereco || {}).StateProvinceId
                : ''
            "
            :disabled="disabled"
            :reduce="state => state.Id"
            @input="(endereco || {}).StateProvinceId = $event"
            @change="
              $emit('updateValue', {
                ...endereco,
                StateProvinceName: (
                  stateProvinces.filter(
                    x => x.Id == (endereco || {}).StateProvinceId
                  )[0] || {}
                ).Name
              })
            "
          >
            <vs-select-item
              :key="index"
              :value="item.Id"
              :text="item.Abbreviation"
              v-for="(item, index) in stateProvinces"
            />
          </vs-select>

          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>

        <ValidationProvider
          name="cidade"
          :rules="{ required: required }"
          v-slot="{ errors }"
          class="md:w-1/2 w-full pt-4"
          tag="vs-col"
        >
          <vs-select
            autocomplete
            class="large w-full border-select"
            label="Cidade"
            :value="
              cities && cities.length && (endereco || {}).CityId
                ? (endereco || {}).CityId
                : ''
            "
            :reduce="city => city.Id"
            :disabled="!(endereco || {}).StateProvinceId || disabled"
            @input="(endereco || {}).CityId = $event"
            @change="
              $emit('updateValue', {
                ...endereco,
                CityName: (
                  cities.filter(x => x.Id == (endereco || {}).CityId)[0] || {}
                ).Name
              })
            "
          >
            <vs-select-item
              :key="index"
              :value="item.Id"
              :text="item.Name"
              v-for="(item, index) in cities"
            />
          </vs-select>

          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </vs-row>

      <slot></slot>

      <vs-row>
        <div class="vx-col w-full text-right">
          <vs-button
            v-if="
              (endereco && (endereco || {}).Id && !(endereco || {}).IsMain) ||
                showCancelButton
            "
            @click="$emit('cancel', endereco.Id)"
            class="button-primary"
            :class="{ 'mr-5': showOkButton }"
            type="border"
          >
            {{ textCancelButton }}
          </vs-button>

          <vs-button
            class="button-primary"
            v-if="showOkButton"
            @click="emitSave()"
            :disabled="invalid"
          >
            Salvar
          </vs-button>
        </div>
      </vs-row>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mask, TheMask } from "vue-the-mask";

export default {
  props: {
    dadosEndereco: { type: Object, require: true },
    textCancelButton: { type: String, default: "Remover" },
    showCancelButton: { type: Boolean, default: false },
    showOkButton: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: true }
  },
  data() {
    return {
      endereco: null,
      cities: []
    };
  },
  directives: { mask },
  components: {
    TheMask
  },
  async mounted() {
    this.loadStateProvinces();
    this.endereco = this.$utils.deepClone(this.dadosEndereco);
  },
  watch: {
    dadosEndereco(value) {
      this.endereco = { ...value };
    }
  },
  computed: {
    ...mapGetters("location-module", ["stateProvinces"])
  },
  methods: {
    ...mapActions("location-module", [
      "searchCEP",
      "getStateProvinces",
      "getCities"
    ]),

    loadStateProvinces() {
      this.$onpoint.loading(() => {
        return this.getStateProvinces().catch(error => {
          this.$onpoint.errorModal(error.response.data.Errors);
        });
      });
    },

    loadCities() {
      if (this.endereco.StateProvinceId)
        this.$onpoint.loading(() => {
          return this.getCities(this.endereco.StateProvinceId)
            .then(response => {
              this.cities = response;
            })
            .catch(error => {
              this.$onpoint.errorModal(error.response.data.Errors);
            });
        });
    },

    buscarCep() {
      const cep = this.$utils.removeSpecialChars(this.endereco.ZipCode);
      if (!cep || cep.length < 8) return;
      this.$onpoint
        .loading(() => {

          return this.searchCEP(this.endereco.ZipCode)
            .then(response => {
              this.endereco = {
                ...this.endereco,
                AddressName: response.AddressName,
                Neighborhood: response.Neighborhood,
                CityId: response.CityId,
                CityName: response.CityName,
                StateProvinceId: response.StateProvinceId,
                StateProvinceName: response.StateProvinceName,
                ZipCode: response.ZipCode
              };
              this.key;
              this.$emit("updateValue", response);

              this.$forceUpdate();
            })
            .catch(error => {
              this.$onpoint.errorModal(error.response.data.Errors);
            });
        })
        .catch(error => {});
    },

    emitSave() {
      let location = {
        Neighborhood: this.endereco.Neighborhood,
        Complement: this.endereco.Complement,
        Number: this.endereco.Number,
        AddressName: this.endereco.AddressName,
        ZipCode: this.endereco.ZipCode,
        Id: this.endereco.Id,
        StateProvinceName: this.endereco.StateProvinceName,
        StateProvinceId: this.endereco.StateProvinceId,
        CityName: this.endereco.CityName,
        CityId: this.endereco.CityId,
        IsMain: this.endereco.IsMain
      };

      this.$emit("save", location);
    }
  }
};
</script>

<style>
.border-select .input-select {
  border-radius: 50px !important;
  padding: 15px;
}

.border-select .input-select:active {
  border: 1px solid rgb(14 101 165);
}

.input-select-label-primary--active {
  color: rgba(0, 0, 0, 0.7);
}
</style>

<style lang="scss" scoped>
.button-error {
  background: rgba(var(--vs-danger), 1) !important;
}
</style>
